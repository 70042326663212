import React, { useState, useRef, useEffect } from "react"
import { Helmet } from 'react-helmet'
import * as Icon from 'react-feather'
import { Link } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'
import Lottie from 'react-lottie';

import { Header } from '../components/header'
import CookieBanner from "../components/cookie-banner"
import BwwFooter from "../components/bww-footer"
//import { FeaturedBookBanner } from '../components/featured-book-banner'

import audienceImg from '../images/homepage/audience@2x.png'
import bookCoverImg from '../images/homepage/last-one-standing-cover@2x.jpg'

import write2raiseCircles from '../images/write-to-raise/write2raise_circles.png';
import write2raiseLogo from '../images/write-to-raise/write2raise-logo.svg';
import leaversHero from '../images/homepage/leavers-hero.png';
import animationData from '../images/write-to-raise/animationHero.json';

const IndexPage=({ location }) => { 

    const [position, setPosition] = useState(false);
    const ref = useRef(null);
    const handleScroll = () => {
        if (ref.current) {
            setPosition(ref.current.getBoundingClientRect().top);
        }
    };

    useEffect(() => {
        setPosition(ref.current.getBoundingClientRect().top);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);

    const animationOptions = {
      loop: false,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
      }
    }
    
    return (
    <>
        <Helmet>
            <title>BoomWriter UK: Class Writing with an Authentic Audience &amp; Purpose</title>
            <meta name="description" content="BoomWriter UK is a class writing tool where all your students write, vote and become published writers" />
            <link rel="canonical" href={`https://go-write.co.uk${location.pathname}`}></link>
        </Helmet>

        <Header className="home-header" position={ position } />

        {/* <section className="homeHero" ref={ ref }>
            <Container>
                <Row>
                    <Col className="homeHero_layout" xl={{span:10, offset:1}}>
                        <h1 className="homeHero_headline heading fontWeight-bolder sentence-case mb-1">Build a <br/><span>Publishing Powerhouse</span> <br/>in your school</h1>
                        <p className="homeHero_byline">Every child in your class published from just £7.99 per child</p>
                        <div className="mt-1">
                            <span className="d-inline-flex headingSmall mr-1 mb-0">KS1 <Icon.CheckCircle className="ml-quarter ks-checkmark"/></span>
                            <span className="d-inline-flex headingSmall mr-1 mb-0">KS2 <Icon.CheckCircle className="ml-quarter ks-checkmark"/></span>
                            <span className="d-inline-flex headingSmall mb-0">KS3 <Icon.CheckCircle className="ml-quarter ks-checkmark" /></span>
                        </div>
                   </Col>
                </Row>
            </Container>
        </section> */}

            <section className="homey6l" ref={ref}>
                <Container>
                    <div className="homey6l_content">
                        <div className="homey6l_content_text">
                            <h1 className="homey6l_title mb-0">Celebrate your school leavers</h1>
                            <h2 className="homey6l_subtitle mb-2">with a keepsake book</h2>
                            <Link to="/leavers" className="white-btn d-inline-block mb-2">Find out more...</Link>
                        </div>
                        <div className="homey6l_content_img">
                            <img src={leaversHero} alt='2023 leavers'/>
                        </div>
                    </div>
                </Container>
            </section>
   

        <div className="homePrimary">
            <Container>
                <Row>
                    <Col sm={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className="text-center mt-xl-3">
                        <h2>Give your class writing a published outcome with BoomWriter UK. Students write, vote and become published authors</h2>
                        {/* <a href={process.env.TEACHER_SIGNUP_WRITE_URL} className="primary-btn d-inline-block">Create Your School Account</a> */}
                    </Col>
                </Row>
                <Row className="mt-2 mb-4 mb-md-6 flex-column flex-md-row">
                    <Col className="text-center mb-2 mb-md-0">
                        <Icon.Users className="icon-highlight usp-icons mb-half mb-lg-1"/><br />
                        <span className="headingSmall secondary-highlight d-block mb-half mb-lg-1">Create An Audience</span>
                        <p>Pupils know they have a real audience, not just their teacher.</p>
                    </Col>
                    <Col className="text-center mb-2 mb-md-0">
                        <Icon.BookOpen className="icon-highlight usp-icons mb-half mb-lg-1"/><br />
                        <span className="headingSmall secondary-highlight d-block mb-half mb-lg-1">Provide a Purpose</span>
                        <p>The ultimate goal of any creative writing: a personalised, published book!</p>
                    </Col>
                    <Col className="text-center">
                        <Icon.TrendingUp className="icon-highlight usp-icons mb-half mb-lg-1"/><br />
                        <span className="headingSmall secondary-highlight d-block mb-half mb-lg-1">Raise Engagement</span>
                        <p>BoomWriter UK is engaging to use and is a motivator for writing.</p>
                    </Col>
                </Row>
            </Container>
        </div>

            {/* <section className="homeWTR">
                <Container className="homeWTR_inner">
                    <div className="homeWTR_text">
                        <div className="homeWTR_logo">
                            <img className="homeWTR_logo_circles" src={write2raiseCircles} alt='BoomWriter and Brian Blessed' />
                            <img className="homeWTR_logo_text" src={write2raiseLogo} alt='Write To Raise' />
                        </div>
                        <div className="homeWTR_brian d-block d-md-none">
                            <Lottie
                                speed={1}
                                options={animationOptions}
                            />
                        </div>
                        <h1 className="heading fontWeight-reg homeWTR_hilight mb-0">Fight the energy crisis with the power of writing!</h1>
                        <h2 className="headingSmall fontWeight-reg mb-2">Write with Brian and raise money for your school.</h2>
                        <Link to="/write-to-raise" className="white-btn">Write To Raise</Link>
                    </div>
                    <div className="homeWTR_brian d-none d-md-block">
                        <Lottie
                            speed={1}
                            options={animationOptions}
                        />
                    </div>
                </Container>
            </section> */}

        <Container>
            <Row className="flex-column flex-md-row align-items-center">
                <Col md={{ order: 1 }} className="text-center">
                    <img className="mb-1 mb-md-0 audience-img" src={ audienceImg } alt="children in a classroom" />
                </Col>
                <Col className="text-center text-md-left">
                    <h3 className="headingSmall highlight">Create an Audience</h3>
                    <p>With BoomWriter UK pupils have an authentic audience for their writing - their classmates. Children peer review each others’ work anonymously to decide a class winner for each piece of writing. This provides fantastic motivation for pupils to not just write, but to write to the best of their ability.</p>
                </Col>
            </Row>
        </Container>

        <section className="secondaryLight-bg angle-lg-top-right">
            <Container className="py-5 py-lg-0">
                <Row className="flex-column flex-md-row align-items-center">
                    <Col md="auto" className="text-center mb-2 mb-md-0">
                        <img className="book-cover mr-md-3 mr-xl-4" src={ bookCoverImg } alt="Last One Standing - book cover for Go Write app book" />
                    </Col>
                    <Col className="text-center text-md-left">
                        <div className="mb-2 mb-md-3">
                            <h4 className="headingSmall highlight">Provide a Purpose</h4>
                            <p>The true purpose of creative writing is of course to be published! Every child receives a personalised book at the end of the project that includes their own writing- all of your pupils will be published writers!</p>
                        </div>
                        <div className="mb-2 mb-md-3">
                            <h4 className="headingSmall highlight">Raising Engagement</h4>
                            <p>BoomWriter UK tackles the barriers around audience and purpose for writing. This key obstacle is at the centre of developing engagement and will ultimately help raise the writing standards of your pupils. </p>
                        </div>
                        <div>
                            <h4 className="headingSmall highlight">Flexible</h4>
                            <p>Create memorable writing opportunities that can be easily adapted to every schools’ planning formats and teaching expectations. Use BoomWriter UK in English lessons or to enhance cross-curricular writing.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="angle-lg-top-right py-5 overlap light-bg">
            <Container>
                <Row className="flex-column flex-md-row mb-md-3">
                    <Col className="text-center px-md-2 px-lg-3 px-xl-5 mb-2 mb-md-0">
                        <Icon.Feather className="icon-highlight usp-icons mb-half mb-lg-1"/><br />
                        <span className="headingSmall secondary-highlight d-block mb-half mb-lg-1">Different Writing Types</span>
                        <p>BoomWriter UK covers a variety of writing types including: narrative, letter writing, diary and poetry. Our books also cover areas such as Online Safety to help further embed safety principles in an innovative way.</p>
                    </Col>
                    <Col className="text-center px-md-2 px-lg-3 px-xl-5 mb-2 mb-md-0">
                        <Icon.Activity className="icon-highlight usp-icons mb-half mb-lg-1"/><br />
                        <span className="headingSmall secondary-highlight d-block mb-half mb-lg-1">Parental Engagement</span>
                        <p>Sharing the writing journey with parents at a publishing celebration assembly brings a school community together in a positive way and can help engage parents with the writing process that goes on within schools.</p>
                    </Col>
                </Row>
                <Row className="flex-column flex-md-row">
                    <Col className="text-center px-md-2 px-lg-3 px-xl-5 mb-2 mb-md-0">
                        <Icon.Globe className="icon-highlight usp-icons mb-half mb-lg-1"/><br />
                        <span className="headingSmall secondary-highlight d-block mb-half mb-lg-1">UK Based</span>
                        <p>BoomWriter UK uses British English through-out and featured specially developed book templates suited for use in UK schools.</p>
                    </Col>
                    <Col className="text-center px-md-2 px-lg-3 px-xl-5">
                        <Icon.Monitor className="icon-highlight usp-icons mb-half mb-lg-1"/><br />
                        <span className="headingSmall secondary-highlight d-block mb-half mb-lg-1">Online Resource</span>
                        <p>BoomWriter UK can be accessed on any device, anywhere with internet access. It could be used within the classroom, for clubs or as a homework tool.</p>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="py-6 contact-section">
            <Container>
                <Row className="justify-content-center layer-up">
                    <Col sm={{ span:9 }} md={{ span:8 }} lg={{ span:6 }} className="text-center py-2">
                        <h4 className="reversed">Affordable at just £7.99/pupil</h4>
                        <p className="reversed mb-2">BoomWriter UK is priced with schools at the heart. Class sets include free postage with discounts for mulitple class sets.</p>
                        <Link to="/pricing" className="reversed-btn uppercase d-inline-block mb-1">
                            Pricing
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>
        <CookieBanner />
        <BwwFooter />
    </>
)}

export default IndexPage
